/** @format */

import React, { useState, createContext } from 'react';
import Modal, { SIZE } from 'src/components/Modal/Modal';

export const ModalContext = createContext({} as ModalContextProps);

export const ModalProvider = props => {
    const [isOpen, setIsOpen] = useState(false);
    const [modalConfig, setModalConfig] = useState({
        onSubmit: () => {},
        onCancel: () => {},
        header: '',
        body: null,
        okButton: {
            show: true,
            disabled: false,
            label: '',
        },
        cancelButton: {
            show: true,
            disabled: false,
            label: '',
        },
    });

    const openModal = clientConfig => {
        setModalConfig({
            ...modalConfig,
            ...clientConfig,
        });
        setIsOpen(true);
    };

    const closeModal = () => {
        modalConfig.onCancel && modalConfig.onCancel();
        setIsOpen(false);
    };

    return (
        <ModalContext.Provider
            value={{
                openModal,
                closeModal,
                modalConfig,
            }}>
            <Modal
                isOpen={isOpen}
                header={modalConfig.header}
                onToggle={() => setIsOpen(!isOpen)}
                buttons={{
                    ok: modalConfig.okButton.show,
                    cancel: modalConfig.cancelButton.show,
                }}
                okLabel={modalConfig.okButton.label}
                okDisabled={modalConfig.okButton.disabled}
                okCallback={modalConfig.onSubmit}
                cancelLabel={modalConfig.cancelButton.label}
                cancelDisabled={modalConfig.cancelButton.disabled}
                cancelCallback={modalConfig.onCancel}>
                {modalConfig.body && (
                    <div>
                        {typeof modalConfig.body === 'function' && <modalConfig.body {...props} />}
                        {typeof modalConfig.body === 'string' && modalConfig.body}
                    </div>
                )}
            </Modal>
            {props.children}
        </ModalContext.Provider>
    );
};

type ModalButtonProps = {
    show?: Boolean;
    disabled?: Boolean;
    label?: string;
};

type ModalConfigProps = {
    onSubmit: Function;
    onCancel: Function;
    header: string;
    body?: any;
    okButton?: ModalButtonProps;
    cancelButton?: ModalButtonProps;
};

type ModalContextProps = {
    openModal: Function;
    closeModal: Function;
    modalConfig: ModalConfigProps;
};
