/** @format */

import React, { memo, useContext, lazy, Suspense } from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';

import { AUTH, STAFFPORTAL, UNAUTHENTICATED, CALENDAR_AUTH } from 'src/settings/constants';

import { AuthContext } from 'src/context/auth';
import { InlineLoader } from 'src/components/InlineLoader/InlineLoader';
import RoleRoutes from './RoleRoutes';

const AdminLayout = lazy(() => import('src/containers/Layout/AdminLayout/AdminLayout'));
const Auth = lazy(() => import('src/containers/Auth/Auth'));
const Logout = lazy(() => import('src/containers/Logout/Logout'));
const StaffPortal = lazy(() => import('src/containers/StaffPortal/StaffPortal'));
const Unauthenticated = lazy(() => import('src/containers/Unauthenticated/Unauthenticated'));
const NotFound = lazy(() => import('src/containers/NotFound/NotFound'));

const { REACT_APP_ENV } = process.env;

const Routes = () => {
    return (
        <Suspense fallback={<InlineLoader />}>
            <Switch>
                {/* temporarily allows production, during UAT */}
                {REACT_APP_ENV !== 'production' &&
                REACT_APP_ENV !== 'development' &&
                REACT_APP_ENV !== 'indie' ? null : (
                    <Route exact path={STAFFPORTAL} component={StaffPortal} />
                )}

                <Route exact path="/" component={Auth} />
                <Route exact path="/logout" component={Logout} />
                <Route exact path={UNAUTHENTICATED} component={Unauthenticated} />

                <Route path="/" component={RoleRoutes} />
                <Route component={NotFound} />
            </Switch>
        </Suspense>
    );
};

export const PrivateRoute = memo(({ children, sidebar, ...rest }: any) => {
    const { isAuthenticated } = useContext(AuthContext);

    const render = (props: any) => {
        const { location } = props;

        if (isAuthenticated && children) {
            return (
                <AdminLayout sidebar={sidebar}>
                    <Suspense fallback={<InlineLoader />}>{React.cloneElement(children, props)}</Suspense>
                </AdminLayout>
            );
        }

        return (
            <Redirect
                to={{
                    pathname: '/',
                    state: { from: location },
                }}
            />
        );
    };

    return <Route {...rest} render={render} />;
});

export default Routes;
