/** @format */

import { getTimeout } from 'src/context/auth';

import { ApolloClient } from 'apollo-client';
import { createHttpLink } from 'apollo-link-http';
import { setContext } from 'apollo-link-context';
import { InMemoryCache } from 'apollo-cache-inmemory';
import { getMainDefinition } from 'apollo-utilities';
// import { split } from 'apollo-link';
// import { WebSocketLink } from 'apollo-link-ws';

// http link
const httpLink = createHttpLink({
    uri: process.env.REACT_APP_API_URL,
});

// websocket link
// const wsLink = new WebSocketLink({
//     uri: process.env.REACT_APP_API_WS,
//     options: {
//         reconnect: true
//     }
// });

// using the ability to split links, you can send data to each link depending on what kind of operation is being sent
// const link = split(({ query }) => {
//     const definition = getMainDefinition(query);
//     return (definition.kind === 'OperationDefinition' && definition.operation === 'subscription');
// }, wsLink, httpLink);

const authLink = setContext((_, { headers }) => {
    const token = localStorage.getItem('token');

    // check timeout
    const isTimeOut = getTimeout();
    if (isTimeOut) window.location.href = `${window.location.origin}/unauthenticated`;

    return {
        headers: {
            ...headers,
            authorization: token || '',
        },
    };
});

export const client = new ApolloClient({
    link: authLink.concat(httpLink),
    cache: new InMemoryCache({ addTypename: false }),
    defaultOptions: {
        watchQuery: {
            fetchPolicy: 'no-cache',
            errorPolicy: 'ignore',
        },
        query: {
            fetchPolicy: 'no-cache',
            errorPolicy: 'ignore',
        },
    },
});
