/** @format */

// **************** ROUTE CONSTANT START **************************

// RESOURCE-BOOKING
export const CALENDAR_AUTH = '/calendar-auth';

// SUGGESTION
export const AUTH = '/auth';
export const UNAUTHENTICATED = '/unauthenticated';
export const STAFFPORTAL = '/staff-portal';
export const LOGOUT = '/logout';

export const SUGGESTIONS = '/my-suggestions';
export const PAST_SUGGESTIONS = '/past-suggestions';
export const ADD_SUGGESTIONS = '/new-suggestions';
export const PDIO_REVIEW = '/pdio-review';
export const DD_REVIEW = '/dd-review';
export const HOME = '/home';

export const SETTINGS = '/settings';
export const STAFF_MEMBERS = '/staff-members';
export const SITE_SETTINGS = '/site-settings';
export const CATEGORIES = '/suggestion-for';
export const AUDIT_LOGS = '/audit-logs';
export const DIVISIONS = '/divisions';
export const DESIGNATIONS = '/designations';
export const TITLES = '/titles';
export const SUGGESTION_LIST = '/suggestion-list';
export const REPORTS = '/reports';

// **************** ROUTE CONSTANT END **************************

export const CURRENCY = '$';
