/** @format */

import React, { lazy, useContext } from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import { PrivateRoute } from 'src/routes/routes';
import { AuthContext } from 'src/context/auth';

import {
    SETTINGS,
    CATEGORIES,
    DIVISIONS,
    DESIGNATIONS,
    TITLES,
    STAFF_MEMBERS,
    AUDIT_LOGS,
    SUGGESTION_LIST,
    REPORTS,

    ADD_SUGGESTIONS,
    SUGGESTIONS,
    PAST_SUGGESTIONS,
    PDIO_REVIEW,
    DD_REVIEW,
    HOME
} from 'src/settings/constants';

const Settings = lazy(() => import('src/containers/Settings/Settings'));
const StaffMembers = lazy(() => import('src/containers/StaffMembers/StaffMembers'));
const Categories = lazy(() => import('src/containers/Categories/Categories'));
const Divisions = lazy(() => import('src/containers/Divisions/Divisions'));
const Designations = lazy(() => import('src/containers/Designations/Designations'));
const Titles = lazy(() => import('src/containers/Titles/Titles'));
const AuditLogs = lazy(() => import('src/containers/AuditLogs/AuditLogs'));
const NotFound = lazy(() => import('src/containers/NotFound/NotFound'));
const SuggestionsList = lazy(() => import('src/containers/SuggestionsList/SuggestionsList'));
const Reports = lazy(() => import('src/containers/Reports/Reports'));

const Home = lazy(() => import('src/containers/Home/Home'));
const Suggestions = lazy(() => import('src/containers/Suggestions/Suggestions'));
const PastSuggestions = lazy(() => import('src/containers/PastSuggestions/PastSuggestions'));
const AddSuggestion = lazy(() => import('src/containers/AddSuggestion/AddSuggestion'));
const PDIOReviews = lazy(() => import('src/containers/PDIOReviews/PDIOReviews'));
const DDReviews = lazy(() => import('src/containers/DDReviews/DDReviews'));

const AdminRoutes = (props: any) => {
    const { role } = (props.location && props.location.state) || {};
    const path = `/${role ? role.toLowerCase() : ''}`;

    return (
        <Switch>
            <Redirect exact from={`${path}`} to={`${HOME}`} />
            <PrivateRoute path={`${HOME}`}>
                <Home />
            </PrivateRoute>

            {/* Settings */}
            <PrivateRoute sidebar path={`${path}${CATEGORIES}`}>
                <Categories />
            </PrivateRoute>
            <PrivateRoute sidebar path={`${path}${DIVISIONS}`}>
                <Divisions />
            </PrivateRoute>
            <PrivateRoute sidebar path={`${path}${DESIGNATIONS}`}>
                <Designations />
            </PrivateRoute>
            <PrivateRoute sidebar path={`${path}${TITLES}`}>
                <Titles />
            </PrivateRoute>
            <PrivateRoute sidebar path={`${path}${SETTINGS}`}>
                <Settings />
            </PrivateRoute>
            <PrivateRoute sidebar path={`${path}${STAFF_MEMBERS}`}>
                <StaffMembers />
            </PrivateRoute>
            <PrivateRoute sidebar path={`${path}${AUDIT_LOGS}`}>
                <AuditLogs />
            </PrivateRoute>
            <PrivateRoute sidebar path={`${path}${SUGGESTION_LIST}`}>
                <SuggestionsList mode="ADMIN" />
            </PrivateRoute>
            <PrivateRoute sidebar path={`${path}${REPORTS}`}>
                <Reports />
            </PrivateRoute>

            {/* User */}
            <PrivateRoute sidebar path={`${path}${ADD_SUGGESTIONS}`}>
                <AddSuggestion />
            </PrivateRoute>
            <PrivateRoute sidebar path={`${path}${SUGGESTIONS}`}>
                <Suggestions />
            </PrivateRoute>
            <PrivateRoute sidebar path={`${path}${PAST_SUGGESTIONS}`}>
                <PastSuggestions mode="PAST" />
            </PrivateRoute>

            {/* PDIO */}
            <PrivateRoute sidebar path={`${path}${PDIO_REVIEW}`}>
                <PDIOReviews mode={role === 'SUPERADMIN' ? 'ADMIN' : "PDIO"} />
            </PrivateRoute>

            {/* DD */}
            <PrivateRoute sidebar path={`${path}${DD_REVIEW}`}>
                <DDReviews mode={role === 'SUPERADMIN' ? 'ADMIN' : "DD"} />
            </PrivateRoute>

            <Route component={NotFound} />
        </Switch>
    );
};

export default AdminRoutes;
