/** @format */

import React from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter, ModalButton, SIZE, ROLE } from 'baseui/modal';

export default (props: any) => {
    const {
        // Modal
        header,
        body,
        size,
        role,
        isOpen,
        onToggle,
        children,
        footer = true,
        buttons={ok: true, cancel: true},

        // Ok
        okLabel,
        okDisabled = false,
        okCallback,

        // Cancel
        cancelLabel,
        cancelDisabled = false,
        cancelCallback,
    } = props;

    const onClose = async (cb?: Function) => {
        onToggle(!isOpen);

        if (cb) {
            setTimeout(async () => {
                cb && (await cb());
            }, 500);
        }
    };

    return (
        <Modal
            animate
            autoFocus
            closeable
            onClose={() => onClose()}
            isOpen={isOpen}
            size={size || SIZE.default}
            role={role || ROLE.dialog}
            {...props}>
            <ModalHeader>{header}</ModalHeader>
            <ModalBody>{children || body}</ModalBody>
            {!footer ? null : (
                <ModalFooter>
                    {buttons.cancel && (
                        <ModalButton disabled={cancelDisabled} onClick={() => onClose(cancelCallback)}>
                            {cancelLabel || 'Cancel'}
                        </ModalButton>
                    )}
                    {buttons.ok && (
                        <ModalButton disabled={okDisabled} onClick={() => onClose(okCallback)}>
                            {okLabel || 'Okay'}
                        </ModalButton>
                    )}
                </ModalFooter>
            )}
        </Modal>
    );
};

export { SIZE, ROLE };
export { ModalBody, ModalFooter, ModalButton};
