/** @format */

import React, { useState, createContext } from 'react';
import { useHistory } from 'react-router-dom';

export const AuthContext = createContext({} as AuthProps);

export const AuthProvider = (props: any) => {
    const history = useHistory();
    const [isAuthenticated, setIsAuthenticated] = useState(isValidToken());

    function getAuthentication() {
        return JSON.parse(localStorage.getItem('user') || '{}');
    }

    function getDefaultRouteByRole(roles: string) {
        if (roles.length) return '/home';
    }

    function setStorage(credential: any) {
        const { roles, jwttoken } = credential;
        localStorage.setItem('token', jwttoken);
        localStorage.setItem(
            'user',
            JSON.stringify({
                ...credential,
                roles: roles.split('-'),
            })
        );
    }

    function authenticate(credential: any) {
        const origin = getDefaultRouteByRole(credential.roles);

        // IMPORTANT
        if (origin === '/error') {
            history.push(origin);
            return;
        }

        setStorage({ ...credential, origin });
        setIsAuthenticated(true);
        history.push(origin);
    }

    function signOut() {
        localStorage.removeItem('user');
        localStorage.removeItem('token');
        history.push('/logout');
        setIsAuthenticated(false);
    }

    return (
        <AuthContext.Provider
            value={{
                isAuthenticated,
                getAuthentication,
                authenticate,
                setStorage,
                signOut,
            }}>
            <>{props.children}</>
        </AuthContext.Provider>
    );
};

const isValidToken = () => {
    const token = localStorage.getItem('token');
    if (token) return true;
    return false;
};

export const getTimeout = () => {
    const { time } = JSON.parse(localStorage.getItem('user')) || {};
    if (!time) return true;

    const formatTime = time.replace(/(\d\d\d\d)(\d\d)(\d\d)(\d\d)(\d\d)/, '$1-$2-$3 $4:$5');
    const diff = (new Date().getTime() - new Date(formatTime).getTime()) / 60000;
    if (diff > 120) {
        localStorage.removeItem('user');
        localStorage.removeItem('token');
    }

    return diff > 120;
};

type AuthProps = {
    isAuthenticated: boolean;
    getAuthentication: Function;
    authenticate: Function;
    setStorage: Function;
    signOut: Function;
};
